.listing {
  font-size: 1.5rem;
  background-color: #444444;
  cursor: pointer;
  padding: 1rem;
  margin: 1rem;
  border-radius: .5rem;
}

.title {
  text-align: center;
  font-size: 3rem;
  color: #DA0037;
}

.player {
  margin: 1rem;
  border-radius: .5rem;
  width: -webkit-fill-available;
}
